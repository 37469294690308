<template>
  <div class="bg">
    <section class="banner">
      <img class="bannerImg" src="../../assets/img/service/banner.jpg" alt="" width="100%">
      <div class="banner-block" >
        <div class="title">
          <h1 class="banner-title hidden-xs-only">金标认证</h1>
          <svg-icon class="banner-line-img hidden-xs-only" svg-name="title-white"></svg-icon>

          <h2
            class="banner-sutitle hidden-xs-only"
          >金标认证服务旨在甄选出在稳定性、兼容性、性能、功耗、安全、隐私等方面均达到行业领先水平的应用，并在小米、OPPO、vivo、联想等品牌的应用市场中向用户推介。提升应用服务品质，提高用户信任感与认同感。</h2>
        </div>
        <router-link to="/console/goldIdentification/list">
            <svg-button
              class="banner-button banner-m-button"
              button-class='banner-button-text'
              svg-name="banner-button"
              svg-hover-name="banner-hover-button"
              text="申请认证"
              text-color="#D8CFA9"
              text-hover-color="#202020"
            ></svg-button>
        </router-link>
      </div>
    </section>

    <div class="wrapBox">
      <div class="content-block">
        <div class="navWrap">
          <div
            class="wrapContent"
            :class="{'active':index == activeIndex}"
            @click="checkItem(item)"
            v-for="(item,index) in navFixedData"
            :key="index"
          >{{item.name}}</div>
        </div>
      </div>
    </div>
    <div class="worth">
      <div class="content-block">
        <el-row>
          <el-col :span="24">
            <section class="titleBox">
              <h3 class="titleContent">认证价值</h3>
              <div class="titleBottom">
                <svg-icon class="titleBottomImg hidden-xs-only" svg-name="title-white"></svg-icon>
              </div>
            </section>
          </el-col>
        </el-row>
        <el-row>
          <el-col
            :span="index == 4?4:5"
            :xs="{span:16,offset:4}"
            v-for="(item,index) in worthData"
            :key="index"
          >
            <div class="itemBox">
              <div class="firstBox">
                  <div class="worthImgBorder">
                      <svg-icon :svg-name="item.src" class="worthImg"></svg-icon>
                  </div>
              </div>
              <div class="secondBox">
                <div class="firstItem">
                  <svg-icon svg-name="start" class="start"></svg-icon>
                  <span>{{item.title}}</span>
                </div>
                <div class="secondtItem">{{item.content}}</div>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="example">
      <div class="content-block">
        <section class="titleBox">
          <h3 class="titleContent">认证示例</h3>
          <div class="titleBottom">
            <svg-icon class="hidden-xs-only titleBottomImg" svg-name="title-black"></svg-icon>
          </div>
        </section>
        <div class="exampleBox">
          <el-row>
            <el-col
              :span="6"
              :xs="{span:24,offset:0,push:0}"
              v-for="(item,index) in exampleData"
              :key="index"
            >
              <div class="exampleItem">
                <div class="exampleImgBox">
                  <img class="exampleIcon" :src="item.icon" alt="认证示例" />
                  <img class="exampleImg" :src="item.src" alt="认证示例" />
                </div>
                <p class="exampleText">{{item.content}}</p>
                <svg-icon class="hidden-xs-only exampleLine" svg-name="phone-line"></svg-icon>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
    <div class="process">
      <div class="content-block">
        <section class="titleBox">
          <h3 class="titleContent">认证流程</h3>
          <div class="titleBottom">
            <svg-icon class="hidden-xs-only titleBottomImg" svg-name="title-white"></svg-icon>
          </div>
        </section>
        <div class="processBox">
          <el-row>
            <el-col
              :span="4"
              :xs="{span:10,offset:1}"
              v-for="(item,index) in processData"
              :key="index"
            >
              <div class="processItem">
                <svg-icon :svg-name="item.src" class="processImg"></svg-icon>
                <div class="processStep">{{item.step}}</div>
                <div class="processText">{{item.content}}</div>
              </div>
              <div class="stepImgBox">
                <img
                  class="stepImg hidden-xs-only"
                  :class="'img'+index"
                  src="@/assets/img/service/step-line.png"
                  alt
                />
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
    <div class="goldApp">
      <div class="content-block">
        <section class="titleBox">
          <h3 class="titleContent">金标应用</h3>
          <div class="titleBottom">
            <svg-icon class="hidden-xs-only titleBottomImg" svg-name="title-white"></svg-icon>
          </div>
        </section>
          <div class="goldAppBox">
            <div class="iconBox" 
              @mouseenter="stop" 
              @mouseleave="start">
              <img @click="prev()" v-if="this.total>18" :src="this.page == 1 ? require(`@/assets/img/service/prev_disable.png`):require(`@/assets/img/service/prev.png`)" alt="" class="prev"/>
              <img @click="next()" v-if="this.total>18" :src="this.page == this.pageNumber ? require(`@/assets/img/service/next_disable.png`):require(`@/assets/img/service/next.png`)" alt="" class="next" />
              <div v-for="(item,index) in appIconList" :key="index">
                <img class="icon" :src="item.apkIcon" alt="">
              </div>
            </div>
            <div class="bottom">
              <span
                v-for="(img_url, index) in pageNumber"
                :key="index"
                :class="{ active: (index+1) === page }"
                @click="getAppIcon(index)"
                @mouseenter="stop"
                @mouseleave="start">
              </span>
            </div>
          </div>
      </div>
    </div>
    <div class="footContentBox">
      <p class="footcontentTitle">获得金标认证，成为应用市场优先推介应用</p>
      <router-link to="/console/goldIdentification/list">
        <svg-button
          class="footButtonBox"
          svg-name="foot-button"
          svg-hover-name="foot-hover-button"
          svg-class-name="footButton"
          text="申请认证"
          text-color="#202020"
          text-hover-color="#D8CFA9"
        ></svg-button>
      </router-link>
    </div>
  </div>
</template>

<script>
import {
  processData,
  worthData,
  navFixedData,
  exampleData
} from "@/common/VO/serviceVO";
import SvgButton from "@/components/SvgButton";
import { singleServiceSever} from "@/common/api-config";
import Swiper from 'swiper';
export default {
  name: "Service",
  metaInfo: {
    title:"ITGSA | 移动智能终端生态联盟 | 金标认证",
    meta: [
      {
        name: 'keywords',
        content: '行业认同，用户信任，应用市场推介，系统服务优先，生态参与优先'
      },
      {
        name: 'description',
        content: '金标认证服务旨在甄选出在稳定性、兼容性、性能、功耗、安全、隐私等方面均达到行业领先水平的应用，并在小米、OPPO、vivo等品牌的应用市场中向用户推介。提升应用服务品质，提高用户信任感与认同感。'
      },
    ]
  },
  components: { SvgButton },
  data() {
    return {
      swiperOption:{
        pagination: ".swiper-pagination",
        loop:true
      },
      navFixedData: navFixedData(),
      activeIndex: 0,
      worthData: worthData(),
      exampleData: exampleData(),
      processData: processData(),
      appIconList:[],
      pageNumber:'',
      total:0,
      page:1,
      perPage:18,
      timer:"",
    };
  },
  mounted() {
    this.$nextTick(function() {
      window.addEventListener("scroll", this.onScroll, {passive: true});
    });
    this.getAppIcon();
    
  },
  destroyed() {
    window.removeEventListener("scroll", this.onScroll, false);
  },
  methods: {
    getAppIcon(index){
      if(index || index==0){
        this.page=index+1
      }
      singleServiceSever.queryAllApk({
        page:this.page,
        perPage:this.perPage,
      }).then(res=>{
        if(res.code==0){
          this.appIconList = res.data.data;
          this.total=res.data.total
          this.pageNumber = Math.ceil(res.data.total/this.perPage)
        }
      })
    },
    
    prev(){
      if(this.page!=1){
        this.page=this.page-1;
        this.getAppIcon();
      }     
    },

    next(){
      if(this.page!=this.pageNumber){
        this.page=this.page+1;
        this.getAppIcon();
      }
      
    },

    stop(){
       clearInterval(this.timer);
    },

    start() {
      this.getAppIcon();//先调用一次
      this.timer=setInterval(() => {
        if(this.page!=this.pageNumber){
          this.page=this.page+1;
          this.getAppIcon();
        }else if(this.page == this.pageNumber){
          this.page=1;
          this.getAppIcon();
        }
      }, 3000);
    },

    onScroll() {
      let scrolled = document.documentElement.scrollTop || document.body.scrollTop;
      if (scrolled >= $(".worth").offset().top - 160) {
        $(".wrapBox").css({'position':'fixed','top':'80px'})
      }else{
        $(".wrapBox").css({'position':'relative','top':'0px'})
      }
      if (scrolled >= $(".process").offset().top - 160) {
        this.activeIndex = 2;
      } else if (
        scrolled >= $(".example").offset().top - 160 &&
        scrolled <= $(".process").offset().top - 20
      ) {
        this.activeIndex = 1;
      } else {
        this.activeIndex = 0;
      }
      if (scrolled >= $(".goldApp").offset().top - 160) {
        this.activeIndex = 3;
      }
    },
    //滚动到指定区域
    checkItem(data) {
      this.activeIndex = data.index;
      let top = document.documentElement.scrollTop || document.body.scrollTop;
      if (top >= $("" + data.class + "").offset().top) {
        this.moveUp(top, data);
      } else {
        this.moveDown(top, data);
      }
    },
    moveUp(top, data) {
      const timeTop = setInterval(() => {
        document.body.scrollTop = document.documentElement.scrollTop = top -= 100;
        if (data.index == 0) {
          if (top <= $("" + data.class + "").offset().top - 200) {
            // 如果是第一个菜单，则当前距离顶部的top值就是0
            document.body.scrollTop = document.documentElement.scrollTop = 0;
            clearInterval(timeTop);
          }
        } else {
          if (top <= $("" + data.class + "").offset().top - 200) {
            // 当滑到菜单的位置时，当前可视化窗口的top值为该菜单所在的top值减去顶部导航菜单的高度
            document.body.scrollTop = document.documentElement.scrollTop =
              $("" + data.class + "").offset().top -
              $("#head").outerHeight();
            clearInterval(timeTop);
          }
        }
      }, 10);
    },
    moveDown(top, data) {
      const timeTop1 = setInterval(() => {
        document.body.scrollTop = document.documentElement.scrollTop = top += 50;
        if (top >= $("" + data.class + "").offset().top - 150) {
          // 当滑到菜单的位置时，当前可视化窗口的top值为该菜单所在的top值减去顶部导航菜单的高度
          document.body.scrollTop = document.documentElement.scrollTop =
            $("" + data.class + "").offset().top - $("#head").outerHeight();
          clearInterval(timeTop1);
        }
      }, 10);
    }
  },
};
</script>

<style lang="scss" scoped>
.bg {
  .banner {
    position: relative;
    width: 100%;
    min-height: 300px;
    background: #fff;
    .bannerImg{
      width: 100%;
      min-height: 300px;
    }
  }

  @media screen and (max-width: 767px) {
    .banner {
      background: url(../../assets/img/service/banner-m.jpg) bottom no-repeat;
      background-size: cover;
      .bannerImg{
        display: none;
      }
    }
  }

  .banner-block {
    position: absolute;
    height: 100%;
    transform: translateX(-50%);
    bottom: 0;
    left: 50%;
    width: 946px;

    .title {
      height: 88%;
      width: 100%;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
  @media screen and (max-width: 767px) {
      .banner-block {
          width: 100%;
      }
  }

  .banner-line-img {
    width: 382px;
    height: 27px;
  }
  .banner-title {
    font-size: 48px;
    line-height: 100px;
    text-align: center;
    font-weight: 900;
    color: #f2e9c1;
  }

  .banner-sutitle {
    margin-top: 20px;
    font-size: 14px;
    text-align: center;
    font-weight: 300;
    color: rgba(242, 233, 193, 1);
    line-height: 20px;
  }

  .banner-button {
    position: absolute;
    font-size: 18px;
    transform: translateX(-50%);
    bottom: 20px;
    left: 50%;
    width: 203px;
    height: 60px;
    cursor: pointer;
  }

  /*banner按钮字体样式*/
  /deep/ .banner-button-text{
      top:-4%;
  }

  .wrapBox {
    width: 100%;
    background-color: white;
    position: sticky;
    top: 80px;
    z-index: 9;
    .navWrap {
      display: flex;
      justify-content: space-around;
      width: 49%;
      margin: auto;
      cursor: pointer;
      .wrapContent {
        font-size: 18px;
        font-weight: 300;
        color: rgba(153, 153, 153, 1);
        line-height: 60px;

        &:hover {
          color: #c1b479;
        }
      }
      .active {
        color: #c1b479;
      }
      .active:after {
        content: "";
        position: absolute;
        display: block;
        margin-left: 10px;
        width: 49px;
        height: 2px;
        background: rgba(211, 199, 146, 1);
      }
    }
    @media screen and (max-width: 767px) {
      .navWrap {
        width: 100%;
      }
    }
  }
  @media only screen and (max-width: 767px) {
    .wrapBox {
      top: 108px;
    }
  }
  .worth {
    box-sizing: border-box;
    background-color: #f8f6f7;
    padding-top: 69px;
    padding-bottom: 85px;
    width: 100%;
    .itemBox {
      width: 230px;
      height: 338px;
      border-radius: 10px;
      margin: 30px 10px;
      .firstBox {
        width: 230px;
        height: 173px;
        border-radius: 10px 10px 0px 0px;
        background-color: #ffff;
        display: flex;
          justify-content: center;
          align-items: center;
          .worthImgBorder{
              width: 112px;
              height: 112px;
              .worthImg {
                  margin: auto;
                  width: 112px;
                  height: 112px;
              }
          }
      }
      .secondBox {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 230px;
        height: 165px;
        border-radius: 0px 0px 10px 10px;
        background: linear-gradient(
          -45deg,
          rgba(195, 186, 147, 1),
          rgba(250, 241, 201, 1)
        );
        .firstItem {
          font-size: 18px;
          font-weight: 600;
          color: #6a4719;
          line-height: 40px;
          .start {
            width: 17px;
            height: 16px;
            margin-right: 7px;
            margin-bottom: 2px;
          }
        }
        .secondtItem {
          width: 169px;
          font-size: 14px;
          font-weight: 300;
          line-height: 24px;
          color: #6a4719;
        }
      }
    }
    @media only screen and (max-width: 767px) {
      /deep/ .el-col-xs-offset-1 {
        margin-left: 2.6667%;
      }
      .itemBox {
        margin: 30px auto;
      }
    }
  }
  .example {
    padding-top: 86px;
    padding-bottom: 20px;
    box-sizing: border-box;
    background: url("~@/assets/img/service/exampleBg.jpg") no-repeat;
    background-size: 100% 100%;
    .exampleBox {
      width: 110%;
      transform: translateX(-5%);
      margin: 45px auto 0px auto;
      .exampleItem {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 20px;
        .exampleImgBox {
          position: relative;
          .exampleIcon{
            position: absolute;
            left: -20px;
            top: 40%;
            width: 80px;
            height: 80px;
          }
          .exampleImg {
            width: 179px;
            height: 382px;
            margin: 64px 0px 23px 0px;
          }
        }

        .exampleText {
          margin-bottom: 6px;
          font-size: 14px;
          font-weight: 400;
          color: rgba(214, 206, 167, 1);
        }
        .exampleLine {
          width: 307px;
          height: 13px;
          background: url("~@/assets/img/service/example-line.png") no-repeat;
          background-size: 100% 100%;
        }
      }
    }
    @media screen and (max-width: 767px) {
      .exampleBox {
        width: 100%;
        margin: 42px auto 0px auto;
      }
    }
  }
  .processItem {
    text-align: center;
    .processImg {
      width: 105px;
      height: 114px;
    }
    .processStep {
      margin-top: 10px;
      color: #999999;
      font-size: 12px;
    }
    .processText {
      margin-top: 10px;
      margin-bottom: 40px;
      color: #333333;
      font-weight: 400;
      font-size: 14px;
    }
  }

  .process {
    padding-top: 69px;
    padding-bottom: 40px;
    box-sizing: border-box;
    background-color: white;
    .processBox {
      margin: 61px auto 0px auto;
      .stepImgBox {
        position: relative;
        .stepImg {
          position: absolute;
          margin-top: -75%;
          margin-left: 82%;
        }
        .img5 {
          display: none;
        }
      }
    }
    @media screen and (max-width: 767px) {
      .processBox {
        width: 100%;
        margin: 42px auto 0px auto;
      }
    }
  }

  .goldApp{
    padding-top: 86px;
    padding-bottom: 20px;
    box-sizing: border-box;
    background: url("~@/assets/img/service/goldAppBj.jpg") no-repeat;
    background-size: 100% 100%;
    height: 903px;
    .goldAppBox{
      margin-top: 120px;
      .iconBox{
        display: flex;
        flex-wrap: wrap;
        width: 1600px;
        height: 400px;
        justify-content: flex-start;
        position: relative;
        // background: #6a4719;
        left:-150px;
        padding-left:110px;
        overflow: hidden;
        .prev{
          width: 24px;
          height: 42px;
          position: absolute;
          top: 35%;
          left: 0;
          cursor: pointer;
        }
        .next{
          width: 24px;
          height: 42px;
          position: absolute;
          top: 35%;
          right:0;
          cursor: pointer;
        }   
      }
      .icon{
        width: 80px;
        height: 80px;
        margin: 0 75px 70px;
      }
      .bottom{
        margin-top: 60px;
        text-align: center;
        span{
          display: inline-block;
          width: 8px;
          height: 8px;
          background: #969075;
          border-radius: 50%;
          margin: 0 5px;
        }
        .active{
          background:#d6cea7;
        }
      }
    }
  }

  .nav-fixed-box {
    position: fixed;
    right: 0px;
    top: 50%;
    transform: translateY(-50%);
    transition: right 0.2s ease;
    width: 87px;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 0px 13px 0px rgba(66, 134, 245, 0.2);
    border-radius: 10px 0 0 10px;
    font-size: 14px;
    z-index: 99;
    .nav-fixed-item {
      padding: 15px 0;
      .activeName {
        opacity: 0.3;
      }
    }
    .nav-fixed-item:hover {
      cursor: pointer;
      .name {
        opacity: 0.3;
      }
    }
  }
}
  @media screen and (max-width: 768px) {
    .bg{
      /*处理 banner-button 遮住文字*/
      .banner-m-button{
        bottom:0 !important;
        width:130px !important;
      }
    }
  }
</style>
