export const processData = function () {
  return [
    {
      src:'注册联盟账号',
      step:'STEP 1',
      content: '注册联盟账号'
    },
    {
      src:'绑定厂商账号',
      step:'STEP 2',
      content: '绑定厂商账号 '
    },
    {
      src:'发布应用',
      step:'STEP 3',
      content: '发布应用 '
    },
    {
      src:'申请认证',
      step:'STEP 4',
      content: '申请认证'
    },
    {
      src:'等待认证',
      step:'STEP 5',
      content: '等待认证通过 '
    },
    {
      src:'获得金标',
      step:'STEP 6',
      content: '获得应用市场金标'
    },
  ]
}
export const worthData = function () {
  return [
    {
      title: '行业认同',
      content: '通过金标认证，表示应用在各指标和要求上达到行业标准，并处于领先地位',
      src:'行业认同'
    }, {
      title: '用户信任',
      content: '获得金标则表示其安全与隐私完全遵循相关标准，提升用户信任，让用户放心',
      src:'用户信任'
    }, {
      title: '应用市场推介',
      content: '将有机会获得应用商店的优先推介，获得更多曝光和流量',
      src:'应用市场推介'
    }, {
      title: '系统服务优先',
      content: '将优先获得系统服务能力的支持，能够优先适配新系统性能',
      src:'系统服务优先'
    }, {
      title: '生态参与优先',
      content: '在生态建设方面能够优先获得参与资格，并扮演重要角色',
      src:'生态参与'
    },
  ]
}
export const navFixedData = function () {
  return [
    {
      name: '认证价值',
      index: 0,
      class:'.worth'
    },
    {
      name: '认证示例',
      index: 1,
      class:'.example'
    },
    {
      name: '认证流程',
      index: 2,
      class:'.process'
    },
    {
      name: '金标应用',
      index: 3,
      class:'.goldApp'
    },
  ]
}
export const exampleData = function () {
  return [
    {
      icon: require('@/assets/img/service/example1-icon.png'),
      src: require('@/assets/img/service/example1.png'),
      content: 'OPPO应用商店金标示意'
    },

    {
      icon: require('@/assets/img/service/example2-icon.png'),
      src: require('@/assets/img/service/example2.png'),
      content: 'vivo应用商店金标示意'
    },
    {
      icon: require('@/assets/img/service/example3-icon.png'),
      src: require('@/assets/img/service/example3.png'),
      content: '小米应用商店金标示意'
    },
    {
      icon: require('@/assets/img/service/example4-icon.png'),
      src: require('@/assets/img/service/example4.png'),
      content: '联想应用商店金标示意'
    },
  ]
}